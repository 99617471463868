<template>
  <div class="createTemplatePage container mx-auto">
    <!-- Header -->
    <div class="py-2">
      <div class="text-2xl font-semibold text-gray-900">Submit New Template</div>
      <!-- <p class="mt-1 text-sm text-gray-700">List of all customer profiles synced with Growlytics.</p> -->
    </div>

    <el-row class="">
      <!-- Left Side Form -->
      <el-col :span="18" class="leftSideForm">
        <el-form class="conversionDeadline" label-position="top" :model="form" ref="createTemplateForm">
          <!-- Basic Details -->
          <div class="mt-2 bg-white border border-gray-300 rounded-lg overflow-hidden">
            <!-- Header Type -->
            <div class="bg-gray-50 px-3 p-2 border-b">
              <div class="font-semibold leading-7 flex">
                <div class="flex-1">Basic Deatils</div>
                <div class="flex p-1" v-if="configuredWhatsAppChannels.length > 0">
                  <div class="w-60 pt-1 text-sm font-normal text-right pr-2">WhatsApp Account:</div>
                  <el-select class="w-full" size="mini" v-model="whatsAppChannel">
                    <el-option v-for="(channel, index) in configuredWhatsAppChannels" :key="index" :label="channel.name" :value="channel.id"> </el-option>
                  </el-select>
                </div>
              </div>
            </div>
            <div class="w-full p-3 bg-white">
              <el-row :gutter="20">
                <el-col :span="8">
                  <div class="text-xs text-gray-500 pb-1">Template Name</div>
                  <el-input size="medium" v-model="form.name" maxlength="100" show-word-limit rows="4" placeholder="Template Name"></el-input>
                  <div v-if="errors['name']" class="text-xs text-red-600 pt-1">{{ errors['name'] }}</div>
                </el-col>
                <el-col :span="6">
                  <div class="text-xs text-gray-500 pb-1">Template Category</div>
                  <el-select class="w-full" size="medium" placeholder="Select Language" v-model="form.category">
                    <el-option v-for="(language, index) in allCategoriesList" :key="index" :label="language.label" :value="language.value"> </el-option>
                  </el-select>
                </el-col>
                <el-col :span="4">
                  <div class="text-xs text-gray-500 pb-1">Language</div>
                  <el-select class="w-full" size="medium" placeholder="Select Language" v-model="form.language" filterable>
                    <el-option v-for="(language, index) in allLanguageList" :key="index" :label="language.label" :value="language.value"> </el-option>
                  </el-select>
                </el-col>
              </el-row>
            </div>
          </div>

          <!-- Header -->
          <div class="mt-2 bg-white border border-gray-300 rounded-lg overflow-hidden">
            <!-- Header Type -->
            <div class="bg-gray-50 px-3 p-2 border-b">
              <div class="font-semibold leading-7">Header</div>
            </div>
            <div class="w-full p-3">
              <!-- <div class="text-sm text-gray-500">Add a title, or, select the media type you want to get approved for this template's header.</div> -->
              <el-radio-group size="small" v-model="form.type" @change="form.sampleMediaUrl = null">
                <el-radio border label="none">No Header</el-radio>
                <el-radio border label="text">Text Header</el-radio>
                <el-radio border label="image">Image Header</el-radio>
                <el-radio border label="video">Video Header</el-radio>
                <el-radio border label="document">Document Header</el-radio>
              </el-radio-group>

              <!-- Text Header Input -->
              <div class="mt-6" v-if="form.type == 'text'">
                <div class="text-xs text-gray-500 pb-1">Header Text (Max 60 characters allowed, including variable values)</div>
                <el-input v-model="form.header" maxlength="60" show-word-limit rows="4" placeholder="Type your template header text here"></el-input>
                <div v-if="errors['header-text']" class="text-xs text-red-600 pt-1">{{ errors['header-text'] }}</div>
              </div>

              <!-- Image Header Input -->
              <div class="mt-6" v-else-if="form.type == 'image'">
                <div class="text-xs text-gray-500 pb-1">Upload Image</div>
                <input ref="templateImageUploadInput" @change="onUploadMedia" class="hidden" type="file" multiple="false" accept="image/*" />
                <el-button size="mini" plain @click="$refs.templateImageUploadInput.click()" type="primary">
                  {{ form.sampleMediaUrl ? 'Change JPG or PNG File' : 'Upload JPG or PNG File' }}
                </el-button>
                <span class="pl-1 text-sm text-gray-400" v-if="form.sampleMediaUrl">{{ form.sampleMediaUrl.split('/').pop() }}</span>
                <span class="pl-1 text-sm text-gray-400" v-else>No Image Selected Yet.</span>

                <div v-if="errors['header-image']" class="text-xs text-red-600 pt-1">{{ errors['header-image'] }}</div>
              </div>

              <!-- Video Header Input -->
              <div class="mt-6" v-else-if="form.type == 'video'">
                <div class="text-xs text-gray-500 pb-1">Upload Video</div>
                <input ref="templateVideoUploadInput" @change="onUploadMedia" class="hidden" type="file" multiple="false" :accept="getAllowedWhatsAppVideoTypes()" />
                <el-button size="mini" plain @click="$refs.templateVideoUploadInput.click()" type="primary">
                  {{ form.sampleMediaUrl ? 'Change MP4 File' : 'Upload MP4 File' }}
                </el-button>
                <span class="pl-1 text-sm text-gray-400" v-if="form.sampleMediaUrl">{{ form.sampleMediaUrl.split('/').pop() }}</span>
                <span class="pl-1 text-sm text-gray-400" v-else>No Video Selected Yet.</span>

                <div v-if="errors['header-video']" class="text-xs text-red-600 pt-1">{{ errors['header-image'] }}</div>
              </div>

              <!-- Document Header Input -->
              <div class="mt-6" v-else-if="form.type == 'document'">
                <div class="text-xs text-gray-500 pb-1">Upload Document</div>
                <input ref="templateDocumentUploadInput" @change="onUploadMedia" class="hidden" type="file" multiple="false" :accept="getAllowedWhatsAppDocumentTypes()" />
                <el-button size="mini" plain @click="$refs.templateDocumentUploadInput.click()" type="primary">
                  {{ form.sampleMediaUrl ? 'Change PDF File' : 'Upload PDF File' }}
                </el-button>
                <span class="pl-1 text-sm text-gray-400" v-if="form.sampleMediaUrl">{{ form.sampleMediaUrl.split('/').pop() }}</span>
                <span class="pl-1 text-sm text-gray-400" v-else>No PDF Selected Yet.</span>

                <div v-if="errors['header-document']" class="text-xs text-red-600 pt-1">{{ errors['header-image'] }}</div>
              </div>
            </div>
          </div>

          <!-- Body -->
          <div class="mt-2 bg-white border border-gray-300 rounded-lg overflow-hidden">
            <div class="bg-gray-50 px-3 p-2 border-b">
              <div class="font-semibold leading-7">Body</div>
              <div class="text-xs text-gray-500 -mt-5 float-right">Max 1024 characters allowed, including variable values.</div>
            </div>

            <div class="p-3">
              <el-input id="messageComposerInputBox" type="textarea" v-model="form.body" maxlength="1024" show-word-limit rows="10" placeholder="Message Text Goes Here"></el-input>
              <div v-if="errors['body']" class="text-xs text-red-600 pt-1">{{ errors['body'] }}</div>
            </div>
          </div>

          <!-- Footer -->
          <div class="mt-2 bg-white border border-gray-300 rounded-lg overflow-hidden">
            <div class="bg-gray-50 px-3 p-2 border-b">
              <div class="font-semibold leading-7">Footer Text (Optional)</div>
              <div class="text-xs text-gray-500 -mt-5 float-right">Max 60 characters allowed, variables not supported in footer.</div>
            </div>
            <!-- <div class="text-sm text-gray-500">Add a short line of text to the bottom of your message template.</div> -->

            <div class="p-3">
              <el-input id="messageComposerInputBox" v-model="form.footer" maxlength="100" show-word-limit placeholder="Type your template footer text here"></el-input>
              <div v-if="errors['footer']" class="text-xs text-red-600 pt-1">{{ errors['footer'] }}</div>
            </div>
          </div>

          <!-- Buttons -->
          <div class="border border-gray-300 overflow-hidden sm:rounded-lg bg-white mt-4">
            <div class="bg-gray-50 px-3 p-2 border-b">
              <div class="font-semibold leading-7">Buttons (Optional)</div>
              <!-- <div class="text-sm text-gray-500">Create buttons that let customers respond to your message or take action.</div> -->
            </div>

            <!-- Button Type -->
            <div class="p-3">
              <el-radio-group size="small" v-model="form.buttonType" @change="onButtonTypeChange">
                <el-radio border label="none">No Buttons</el-radio>
                <el-radio border label="cta">Call To Action</el-radio>
                <el-radio border label="quick-reply">Quick Reply</el-radio>
              </el-radio-group>
            </div>

            <div class="border-t p-3" v-if="form.buttonType != 'none'">
              <!-- Buttons List -->
              <div class="mb-3 bg-gray-50 border rounded-lg p-2" v-for="(button, buttonIndex) in form.buttons" :key="buttonIndex">
                <el-row class="p-1" :gutter="10">
                  <!-- Button Text -->
                  <el-col :span="6">
                    <div class="text-xs text-gray-500">{{ form.buttonType == 'cta' ? 'Button Text' : 'Quick Reply Button Text' }}</div>
                    <div class="mt-1">
                      <el-input v-model="button.text" maxlength="20" show-word-limit></el-input>
                      <div v-if="errors['button-' + buttonIndex + '-text']" class="text-xs text-red-600 pt-1">{{ errors['button-' + buttonIndex + '-text'] }}</div>
                    </div>
                  </el-col>

                  <template v-if="form.buttonType == 'cta'">
                    <!-- Button Type -->
                    <el-col :span="5">
                      <div class="text-xs text-gray-500">Button Type</div>
                      <div class="mt-1">
                        <el-select v-model="button.subType" @change="onCTAButtonSubTypeChange(buttonIndex)" class="w-full">
                          <el-option label="Call Phone Number" value="PHONE_NUMBER"> </el-option>
                          <el-option label="Visit Website" value="URL"> </el-option>
                        </el-select>
                      </div>
                    </el-col>

                    <!-- Action Type - Visit Website -->
                    <el-col :span="11" v-if="button.subType == 'URL'">
                      <div class="text-xs text-gray-500">Website URL</div>
                      <div class="mt-1">
                        <div class="border w-full px-2 leading-9 cursor-not-allowed rounded bg-white text-gray-500 text-sm">{{ button.value }}</div>
                        <div class="text-xs mt-1 text-gray-500">No need to put button url for tempalte approval.</div>
                      </div>
                    </el-col>

                    <el-col class="flex gap-x-2" :span="11" v-else>
                      <!-- Country Code -->
                      <div class="w-24">
                        <div class="text-xs text-gray-500">Country Code</div>
                        <div class="mt-1">
                          <el-select v-model="button.actionType" class="w-full">
                            <el-option v-for="(country, countryIndex) in countryCodeList" :key="countryIndex" :label="country.phoneCode" :value="country.phoneCode"> </el-option>
                          </el-select>
                        </div>
                      </div>
                      <!-- Phone Number -->
                      <div class="flex-1">
                        <template v-if="button.subType == 'URL'"> </template>
                        <template v-if="button.subType == 'PHONE_NUMBER'">
                          <div class="text-xs text-gray-500">Phone Number</div>
                          <div class="mt-1">
                            <el-input v-model="button.value" maxlength="20" placeholder="Phone Number"></el-input>
                            <div v-if="errors['button-' + buttonIndex + '-value']" class="text-xs text-red-600 pt-1">{{ errors['button-' + buttonIndex + '-value'] }}</div>
                          </div>
                        </template>
                      </div>
                    </el-col>
                  </template>

                  <!-- Delete Button -->
                  <el-col :span="2" class="pl-2">
                    <div>&nbsp;</div>
                    <el-button size="medium" @click="form.buttons.splice(buttonIndex, 1)" type="danger" icon="el-icon-delete" circle plain></el-button>
                  </el-col>
                </el-row>
              </div>

              <!-- Add More Button -->
              <div class="">
                <el-button type="primary" size="small" @click="onAddButtonClick" v-if="form.buttons.length < 3"> Add Button </el-button>
              </div>
            </div>
          </div>

          <!-- Submit Template -->
          <div class="w-full text-center py-10">
            <el-button type="primary" :loading="formattingTemplateLoader" @click="onShowSubmitPopup">Submit Template</el-button>
          </div>
        </el-form>
      </el-col>

      <!-- Right Side Preview -->
      <el-col class="rightSidePreview p-2" :span="6">
        <div class="whatsAppPreviewContainer rounded-md w-80 p-2">
          <div class="rounded p-2.5 bg-white border m-auto">
            <!-- Header -->
            <div v-if="form.type == 'text'" class="text-black font-bold mb-2 text-sm">
              {{ form.header }}
            </div>
            <div v-else-if="form.type == 'image'">
              <img :src="form.sampleMediaUrl" class="w-full rounded-md mb-3" />
            </div>
            <div v-else-if="form.type == 'video'">
              <!-- TODO -->
              <video v-if="form.sampleMediaUrl" controls class="mb-3 rounded-md w-full">
                <source :src="form.sampleMediaUrl" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div v-else-if="form.type == 'document'">
              <div class="w-full bg-gray-100 rounded-md text-center py-12" v-if="form.sampleMediaUrl">
                <i class="fa-duotone fa-file-pdf text-4xl"></i>
              </div>
            </div>

            <!-- Body -->
            <div v-if="form.body" class="whitespace-pre-line text-sm">{{ form.body }}</div>

            <!-- Footer -->
            <div v-if="form.footer" class="whitespace-pre-line mt-3 text-gray-400 text-sm">{{ form.footer }}</div>
          </div>
          <!-- Buttons -->
          <template v-if="form.buttonType != 'none'">
            <div class="mt-1 bg-white px-2 py-1 rounded-md text-blue-500 text-sm" v-for="(button, index) in form.buttons" :key="index">
              {{ button.text }}
            </div>
          </template>
        </div>
      </el-col>
    </el-row>

    <!-- Submit Template Button -->
    <el-dialog append-to-body custom-class="sendTemplateMessageDialog" :visible.sync="showTemplateSubmitPopup" width="900px" center des title="Submit Template" :destroy-on-close="true">
      <!-- Message -->
      <div class="py-2 text-xs text-center bg-gray-700 text-white">Provide dynamic variables(if any) to get template approved.</div>

      <TemplateInputWithPreview ref="TemplateInputWithPreview"></TemplateInputWithPreview>

      <!-- Footer -->
      <template #footer>
        <div class="text-right w-full py-0">
          <template>
            <el-button :loading="submitTemplateLoader" size="medium" @click="onSubmitTemplate" type="primary">Submit Template</el-button>
            <el-button size="medium" @click="showTemplateSubmitPopup = false" type="danger">Cancel</el-button>
          </template>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<style lang="scss" src="./createTemplate.scss"></style>

<script>
import createTemplateComponent from './createTemplateComponent';
export default createTemplateComponent;
</script>

const axios = require('axios');
const { chatServiceUrl, buildAuthHeaders } = require('./settings.js');

exports.uploadImageAsset = function (formData) {
  let headers = buildAuthHeaders();
  headers['Content-Type'] = 'multipart/form-data';
  return axios.post(chatServiceUrl + '/media/image', formData, {
    headers: headers,
    timeout: 1000 * 600
  });
};

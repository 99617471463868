<template>
  <div class="templateInputWithPreviewComponent">
    <!-- Template Info -->
    <el-row v-if="template">
      <!-- Left Side - Form -->
      <el-col v-if="!onlyPreview" :span="12" class="leftSideForm p-3">
        <!-- Header -->
        <template>
          <!-- Image Header -->
          <div class="mb-6" v-if="template.type.toUpperCase() == 'IMAGE'">
            <div class="font-bold mb-2">Image URL</div>
            <el-input size="medium" placeholder="Enter Image URL" v-model="variables.mediaUrl"> </el-input>
            <div class="text-red text-xs text-red-600 mt-1" v-if="validationErrors['mediaUrl']">{{ validationErrors['mediaUrl'] }}</div>
          </div>
          <!-- Video Header -->
          <div class="mb-6" v-if="template.type.toUpperCase() == 'VIDEO'">
            <div class="font-bold mb-2">Video URL</div>
            <el-input size="medium" placeholder="Enter Video URL" v-model="variables.mediaUrl"> </el-input>
            <div class="text-red text-xs text-red-600 mt-1" v-if="validationErrors['mediaUrl']">{{ validationErrors['mediaUrl'] }}</div>
          </div>
          <!-- Document Header -->
          <div class="mb-6" v-if="template.type.toUpperCase() == 'DOCUMENT'">
            <div class="font-bold mb-2">Document URL</div>
            <el-input size="medium" placeholder="Enter Document URL" v-model="variables.mediaUrl"> </el-input>
            <div class="text-red text-xs text-red-600 mt-1" v-if="validationErrors['mediaUrl']">{{ validationErrors['mediaUrl'] }}</div>
          </div>

          <!-- Text Header -->
          <div class="mb-6" v-if="template.type.toUpperCase() == 'TEXT' && variables.header.length > 0">
            <div class="font-bold mb-2">Header Variables</div>
            <div class="mb-2" v-for="(variable, index) in variables.header" :key="index">
              <el-input size="medium" placeholder="Enter Dynamic Value" v-model="variable.value">
                <template slot="prepend">{{ variable.label }}</template>
              </el-input>
              <div class="text-red text-xs text-red-600 mt-1" v-if="validationErrors['header' + index]">{{ validationErrors['header' + index] }}</div>
            </div>
          </div>
        </template>

        <!-- Body -->
        <div class="mb-6" v-if="variables.body.length > 0">
          <div class="font-bold mb-2">Body Variables</div>
          <div class="mb-2" v-for="(variable, bodyIndex) in variables.body" :key="bodyIndex">
            <el-input size="medium" placeholder="Enter Dynamic Value" v-model="variable.value">
              <template slot="prepend">{{ variable.label }}</template>
            </el-input>
            <div class="text-red text-xs text-red-600 mt-1 mb-2" v-if="validationErrors['body' + bodyIndex]">{{ validationErrors['body' + bodyIndex] }}</div>
          </div>
        </div>

        <!-- Buttons -->
        <div class="mb-4" v-if="variables.button.length > 0">
          <div class="font-bold">Button Variables</div>
          <div class="mt-2 mb-3 border rounded-md overflow-hidden" v-for="(variable, index) in variables.button" :key="index">
            <div class="bg-gray-100 p-2 border-b">
              <div class="text-sm">{{ variable.label }}</div>
              <div v-if="variable.description" class="text-xs text-gray-400">{{ variable.description }}</div>
            </div>
            <div class="mb-2 px-2 pt-2">
              <el-input size="medium" placeholder="https://mysite.com/home" v-model="variable.value"> </el-input>
            </div>
            <div class="text-red text-xs text-red-600 mx-2 my-1" v-if="validationErrors['button' + index]">{{ validationErrors['button' + index] }}</div>
          </div>
        </div>
      </el-col>

      <!-- Right Side - Preview -->
      <el-col :span="onlyPreview ? 24 : 12" class="p-3 bg-cover" :style="{ backgroundImage: 'url(' + require('@/assets/images/whatsapp-chat-background.png') + ')', 'min-height': '400px' }">
        <div style="width: 350px">
          <div class="rounded-md p-2.5 bg-white shadow-sm">
            <!-- Text Header -->
            <div v-if="template.type.toLowerCase() == 'text'" class="mb-2 text-sm text-gray-400">
              {{ template.header }}
            </div>
            <!-- Image Header -->
            <div v-else-if="template.type.toLowerCase() == 'image'">
              <img v-if="variables.mediaUrl" :src="variables.mediaUrl" class="w-full rounded-md mb-3" />
              <template v-else>
                <img src="@/assets/images/image-preview.png" class="w-full rounded-md overflow-hidden mb-3" />
              </template>
              <!-- <img :src="template.mediaUrl" class="w-full rounded-md mb-3" /> -->
            </div>
            <!-- Video Header -->
            <div v-else-if="template.type.toLowerCase() == 'video'">
              <img v-if="variables.mediaUrl" :src="variables.mediaUrl" class="w-full rounded-md mb-3" />
              <template v-else>
                <img src="@/assets/images/video-preview.png" class="w-full rounded-md overflow-hidden mb-3" />
              </template>
            </div>
            <!-- Document Header -->
            <div v-else-if="template.type.toLowerCase() == 'document'" class="mb-3 rounded-md bg-gray-300 py-4 text-center text-white">
              <i style="font-size: 100px" class="fa-solid fa-file"></i>
            </div>

            <!-- Body -->
            <div class="whitespace-pre-line text-sm text-black">{{ template.body }}</div>

            <!-- Footer -->
            <div v-if="template.footer" class="whitespace-pre-line mt-3 text-gray-400 text-sm">{{ template.footer }}</div>
          </div>

          <!-- Buttons -->
          <template v-if="template.hasButtons">
            <div class="mt-2 rounded-md px-2.5 py-2 bg-white shadow-sm text-blue-500" v-for="(button, index) in template.buttons" :key="index">
              {{ button.text }}
            </div>
          </template>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<style lang="scss" src="./templateInputWithPreview.scss"></style>

<script>
import templateInputWithPreviewComponent from './templateInputWithPreviewComponent';
export default templateInputWithPreviewComponent;
</script>

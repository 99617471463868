export default {
  name: 'TemplateInputWithPreviewComponent',
  components: {},
  props: {
    onlyPreview: {
      required: false,
      default: false
    }
  },
  data() {
    return {
      template: null,
      variables: null,

      validationErrors: {}
    };
  },

  methods: {
    applyTemplate(template, rawVariables, templateInfo) {
      let variables = JSON.parse(JSON.stringify(rawVariables));

      // Parse Headers
      for (let i = 0; i < variables.header.length; i++) {
        variables.header[i] = {
          label: variables.header[i],
          value: ''
        };
      }

      // Parse Body
      for (let i = 0; i < variables.body.length; i++) {
        variables.body[i] = {
          label: variables.body[i],
          value: ''
        };
      }

      // Parse Buttons
      for (let i = 0; i < variables.button.length; i++) {
        variables.button[i] = {
          label: variables.button[i],
          description: templateInfo?.buttons[i].value ?? null,
          value: ''
        };
      }

      this.variables = variables;
      this.template = template;
      this.validationErrors = {};
    },

    getProcessedInputForSend() {
      // Validate Media URL
      let response = JSON.parse(JSON.stringify(this.variables));

      // // Parse Headers
      // for (let i = 0; i < response.header.length; i++) {
      //   response.header[i] = response.header[i].value;
      // }

      // // Parse Body
      // for (let i = 0; i < response.body.length; i++) {
      //   response.body[i] = response.body[i].value;
      // }

      // // Parse Buttons
      // for (let i = 0; i < response.button.length; i++) {
      //   response.body[i] = response.body[i].value;
      // }

      return response;
    },

    isValidInput() {
      let validationErrors = {};
      let isValid = true;

      // Validate Image
      if (['IMAGE', 'VIDEO', 'DOCUMENT'].indexOf(this.template.type.toUpperCase()) >= 0) {
        if (!this.variables.mediaUrl) {
          validationErrors['mediaUrl'] = this.template.type.toUpperCase() + ' is required.';
          isValid = false;
        }
      }

      // Parse Headers
      for (let i = 0; i < this.variables.header.length; i++) {
        if (!this.variables.header[i].value) {
          validationErrors['header' + i] = 'Header variable is required.';
          isValid = false;
        }
      }

      // Parse Body
      for (let i = 0; i < this.variables.body.length; i++) {
        if (!this.variables.body[i].value) {
          validationErrors['body' + i] = 'Body variable is required.';
          isValid = false;
        }
      }

      // Parse Buttons
      for (let i = 0; i < this.variables.button.length; i++) {
        if (!this.variables.button[i].value) {
          validationErrors['button' + i] = 'Button variable is required.';
          isValid = false;
        }

        // else if (!this.variables.button[i].value.startsWith('http')) {
        //   validationErrors['button' + i] = 'Button variable must be a valid URL starting with http.';
        //   isValid = false;
        // }
      }
      this.validationErrors = validationErrors;

      return isValid;
    }
  },
  created() {}
};

import mediaAssetService from '@/services/mediaAssetService';
import templateService from '../../../services/templateService';
import TemplateInputWithPreview from '@/components/sendTemplateMessagePopup/templateInputWithPreview/templateInputWithPreview';
import ChatSettingsService from '../../../services/chatSettingsService';
// import moment from 'moment';

export default {
  name: 'CreateTemplate',
  components: {
    TemplateInputWithPreview
  },
  data() {
    return {
      allLanguageList: [
        {
          label: 'Afrikaans',
          value: 'af'
        },
        {
          label: 'Albanian',
          value: 'sq'
        },
        {
          label: 'Arabic',
          value: 'ar'
        },
        {
          label: 'Azerbaijani',
          value: 'az'
        },
        {
          label: 'Bengali',
          value: 'bn'
        },
        {
          label: 'Bulgarian',
          value: 'bg'
        },
        {
          label: 'Catalan',
          value: 'ca'
        },
        {
          label: 'Chinese (CHN)',
          value: 'zh_CN'
        },
        {
          label: 'Chinese (HKG)',
          value: 'zh_HK'
        },
        {
          label: 'Chinese (TAI)',
          value: 'zh_TW'
        },
        {
          label: 'Croatian',
          value: 'hr'
        },
        {
          label: 'Czech',
          value: 'cs'
        },
        {
          label: 'Danish',
          value: 'da'
        },
        {
          label: 'Dutch',
          value: 'nl'
        },
        {
          label: 'English',
          value: 'en'
        },
        {
          label: 'English (UK)',
          value: 'en_GB'
        },
        {
          label: 'English (US)',
          value: 'en_US'
        },
        {
          label: 'Estonian',
          value: 'et'
        },
        {
          label: 'Filipino',
          value: 'fil'
        },
        {
          label: 'Finnish',
          value: 'fi'
        },
        {
          label: 'French',
          value: 'fr'
        },
        {
          label: 'Georgian',
          value: 'ka'
        },
        {
          label: 'German',
          value: 'de'
        },
        {
          label: 'Greek',
          value: 'el'
        },
        {
          label: 'Gujarati',
          value: 'gu'
        },
        {
          label: 'Hausa',
          value: 'ha'
        },
        {
          label: 'Hebrew',
          value: 'he'
        },
        {
          label: 'Hindi',
          value: 'hi'
        },
        {
          label: 'Hungarian',
          value: 'hu'
        },
        {
          label: 'Indonesian',
          value: 'id'
        },
        {
          label: 'Irish',
          value: 'ga'
        },
        {
          label: 'Italian',
          value: 'it'
        },
        {
          label: 'Japanese',
          value: 'ja'
        },
        {
          label: 'Kannada',
          value: 'kn'
        },
        {
          label: 'Kazakh',
          value: 'kk'
        },
        {
          label: 'Kinyarwanda',
          value: 'rw_RW'
        },
        {
          label: 'Korean',
          value: 'ko'
        },
        {
          label: 'Kyrgyz (Kyrgyzstan)',
          value: 'ky_KG'
        },
        {
          label: 'Lao',
          value: 'lo'
        },
        {
          label: 'Latvian',
          value: 'lv'
        },
        {
          label: 'Lithuanian',
          value: 'lt'
        },
        {
          label: 'Macedonian',
          value: 'mk'
        },
        {
          label: 'Malay',
          value: 'ms'
        },
        {
          label: 'Malayalam',
          value: 'ml'
        },
        {
          label: 'Marathi',
          value: 'mr'
        },
        {
          label: 'Norwegian',
          value: 'nb'
        },
        {
          label: 'Persian',
          value: 'fa'
        },
        {
          label: 'Polish',
          value: 'pl'
        },
        {
          label: 'Portuguese (BR)',
          value: 'pt_BR'
        },
        {
          label: 'Portuguese (POR)',
          value: 'pt_PT'
        },
        {
          label: 'Punjabi',
          value: 'pa'
        },
        {
          label: 'Romanian',
          value: 'ro'
        },
        {
          label: 'Russian',
          value: 'ru'
        },
        {
          label: 'Serbian',
          value: 'sr'
        },
        {
          label: 'Slovak',
          value: 'sk'
        },
        {
          label: 'Slovenian',
          value: 'sl'
        },
        {
          label: 'Spanish',
          value: 'es'
        },
        {
          label: 'Spanish (ARG)',
          value: 'es_AR'
        },
        {
          label: 'Spanish (SPA)',
          value: 'es_ES'
        },
        {
          label: 'Spanish (MEX)',
          value: 'es_MX'
        },
        {
          label: 'Swahili',
          value: 'sw'
        },
        {
          label: 'Swedish',
          value: 'sv'
        },
        {
          label: 'Tamil',
          value: 'ta'
        },
        {
          label: 'Telugu',
          value: 'te'
        },
        {
          label: 'Thai',
          value: 'th'
        },
        {
          label: 'Turkish',
          value: 'tr'
        },
        {
          label: 'Ukrainian',
          value: 'uk'
        },
        {
          label: 'Urdu',
          value: 'ur'
        },
        {
          label: 'Uzbek',
          value: 'uz'
        },
        {
          label: 'Vietnamese',
          value: 'vi'
        },
        {
          label: 'Zulu',
          value: 'zu'
        }
      ],
      allCategoriesList: [
        {
          label: 'Marketing',
          value: 'MARKETING'
        },
        {
          label: 'Utility',
          value: 'UTILITY'
        },
        {
          label: 'Authentication',
          value: 'AUTHENTICATION'
        }
      ],

      form: {
        name: '',
        category: 'MARKETING',
        language: 'en',

        type: 'none',
        // sampleMediaUrl: 'https://s3.ap-south-1.amazonaws.com/chatassets.growlytics.in/1xh60ckasgkpe8ja7w/template-approval/9b5841b8-633c-458b-bcbf-5a3d0d4eb0ef.jpeg',
        sampleMediaUrl: null,

        header: '',
        body: ``,
        footer: '',

        buttonType: 'none',
        buttons: []
      },

      // WhatsApp Channel List
      whatsAppChannel: null,
      configuredWhatsAppChannels: [],

      // Additional Variables For UI
      buttonTemplate: {
        text: '',
        type: null,
        subType: null,

        actionType: null,
        value: 'https://i.gltcs.in/{{1}}'
      },
      countryCodeList: null,

      // Template Submit Logic Varaibles
      errors: {},
      formattedTemplate: null,
      formattingTemplateLoader: false,
      showTemplateSubmitPopup: false,
      submitTemplateLoader: false
    };
  },
  methods: {
    async fetchConfiguredWhatsAppChannels() {
      try {
        let response = await ChatSettingsService.getChannelListForDropdown({
          channelType: 'whatsApp'
        });
        this.configuredWhatsAppChannels = response.data;

        // Set Default Selected Channel.
        if (this.configuredWhatsAppChannels.length > 0) {
          this.whatsAppChannel = this.configuredWhatsAppChannels[0].id;
        }
      } catch (err) {
        console.error('Failed to fetch configured whatsapp channels.', err);
      }
    },

    //#region ---------------------- Start: Button Management ----------------------

    onButtonTypeChange() {
      this.form.buttons = [];
    },

    onAddButtonClick() {
      let button = JSON.parse(JSON.stringify(this.buttonTemplate));
      button.type = this.form.buttonType;
      if (button.type == 'cta') {
        button.subType = 'URL';
      }
      this.form.buttons.push(button);
    },

    onCTAButtonSubTypeChange(buttonIndex) {
      // change sub type
      if (this.form.buttons[buttonIndex].subType == 'URL') {
        this.form.buttons[buttonIndex].value = 'https://i.gltcs.in/{{1}}';
      } else if (this.form.buttons[buttonIndex].subType == 'PHONE_NUMBER') {
        this.form.buttons[buttonIndex].actionType = '91';
        // Clear current value.
        this.form.buttons[buttonIndex].value = '';
      }
    },

    //#endregion ---------------------- End: Button Management ----------------------

    //#region ---------------------- Start: Upload Management ----------------------

    async onUploadMedia(event) {
      const loading = this.$loading({
        lock: true,
        text: 'Uploading',
        spinner: 'el-icon-loading',
        background: '#f5f7fad9'
      });

      try {
        let file = event.target.files[0];

        // Validate File Size.
        let fileSize = parseInt(file.size / 1024 / 1024); // MB
        if (this.form.type == 'image') {
          // If image, max 5Mb Allowed.
          if (fileSize > 5) {
            this.warningToast('Max allowed file size is 5MB.');
            loading.close();
            return;
          }
        } else {
          // Else, max 15Mb Allowed.
          if (fileSize > 15) {
            this.warningToast('Max allowed file size is 15MB.');
            loading.close();
            return;
          }
        }

        this.uploadingImage = true;
        let formData = new FormData();
        formData.append('file', file);

        let result = await mediaAssetService.uploadImageAsset(formData, this);

        this.form.sampleMediaUrl = result.data[0].url;
      } catch (err) {
        console.log('Image upload media', err);
        this.errorToast('Failed to upload media, plase contact support.');
      } finally {
        loading.close();
      }
    },

    //#endregion ---------------------- End: Upload Management ----------------------

    //#region ---------------------- Start: Submit Template ----------------------

    validateForm() {
      this.errors = {};
      let hasErrors = false;

      // Validate Template Name
      if (!this.form.name) {
        this.errors['name'] = 'Template name is required';
        hasErrors = true;
      } else if (this.form.name.startsWith('_')) {
        this.errors['name'] = 'Name can not start with underscore.';
        hasErrors = true;
      } else if (!/^[a-z0-9_]*$/.test(this.form.name)) {
        this.errors['name'] = 'Name can only have alphabets(a-z), numbers(0-9), and underscores(_). Capital letters not allowed.';
        hasErrors = true;
      } else if (this.form.name.length > 100) {
        this.errors['name'] = 'Name can not exceed 100 characters.';
        hasErrors = true;
      }

      // Validate Header.
      if (this.form.type == 'text') {
        if (!this.form.header) {
          this.errors['header-text'] = 'Please provide header text.';
          hasErrors = true;
        }
      } else if (this.form.type == 'image') {
        if (!this.form.sampleMediaUrl) {
          this.errors['header-image'] = 'Please provide image for template approval review.';
          hasErrors = true;
        }
      } else if (this.form.type == 'video') {
        if (!this.form.sampleMediaUrl) {
          this.errors['header-video'] = 'Please sample video for template approval review.';
          hasErrors = true;
        }
      } else if (this.form.type == 'document') {
        if (!this.form.sampleMediaUrl) {
          this.errors['header-document'] = 'Please sample document for template approval review.';
          hasErrors = true;
        }
      }

      // Validate Body.
      if (!this.form.body) {
        this.errors['body'] = 'Please provide message body text.';
        hasErrors = true;
      }

      // Validate Footer.
      if (this.form.footer && this.form.footer.length > 50) {
        this.errors['footer'] = 'Please provide message body text.';
        hasErrors = true;
      }

      // Validate Buttons
      for (let i = 0; i < this.form.buttons.length; i++) {
        if (!this.form.buttons[i].text) {
          this.errors['button-' + i + '-text'] = 'Button text is required.';
          hasErrors = true;
        } else if (this.form.buttons[i].text.length > 20) {
          this.errors['button-' + i + '-text'] = 'Max 20 characters allowed.';
          hasErrors = true;
        }

        if (this.form.buttonType == 'cta') {
          if (!this.form.buttons[i].value) {
            this.errors['button-' + i + '-value'] = 'This field is required.';
            hasErrors = true;
          }
        }
      }

      console.log('validation result', this.errors);
      return !hasErrors;
    },

    async onShowSubmitPopup() {
      try {
        // Validate Form.
        let validationResult = this.validateForm();
        if (!validationResult) {
          this.errorToast('Please provide correct template fields.');
          return;
        }

        this.formattingTemplateLoader = true;
        let response = await templateService.parseTemplateFromDashboardForm(this.form);
        this.formattedTemplate = response.data.template;

        this.showTemplateSubmitPopup = true;
        requestAnimationFrame(() => {
          this.$refs.TemplateInputWithPreview.applyTemplate(response.data.template, response.data.variables);
        });
        console.log('Parse Response is', response.data);
      } catch (err) {
        this.errorToast('Failed to submit template. Please contact support.');
        console.error('Failed to fetch template preview.', err);
      } finally {
        this.formattingTemplateLoader = false;
      }
    },

    async onSubmitTemplate() {
      try {
        this.submitTemplateLoader = true;

        // Check if variables are valid.
        let isInputValid = this.$refs.TemplateInputWithPreview.isValidInput();
        if (!isInputValid) {
          this.warningToast('Please provide valid variables for template submit.');
          return;
        }

        // Read Variables Provided.
        let templateInput = this.$refs.TemplateInputWithPreview.getProcessedInputForSend();

        let response = await templateService.submitTemplate(
          {
            template: this.form,
            variables: templateInput
          },
          this.whatsAppChannel
        );

        console.log('Submit response', response.data);
        if (response.data.success) {
          this.successToast('Template Submitted Successfully.');
          this.$router.replace('/templates');
        } else {
          let errorDescription = response.data.message;
          if (!errorDescription) errorDescription = 'Failed to submit template, please contact support.';
          this.$alert(errorDescription, 'Template Submit Failed', {
            confirmButtonText: 'OK'
          });
          // this.errorToast('Failed to submit template, please contact support.');
        }
      } catch (err) {
        this.errorToast('Failed to submit template, please contact support.');
        console.error('Failed to send template message.', err);
      } finally {
        this.submitTemplateLoader = false;
      }
    }

    //#endregion ---------------------- End: Submit Template ----------------------
  },

  mounted() {
    this.countryCodeList = this.getCountryCodeList();
    this.fetchConfiguredWhatsAppChannels();
  },

  beforeDestroy() {}
};

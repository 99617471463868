const axios = require('axios');
const { chatServiceUrl, buildAuthHeaders } = require('./settings.js');

exports.getList = function (params) {
  return axios.post(chatServiceUrl + '/template/list', params, {
    headers: buildAuthHeaders()
  });
};

exports.getTemplateListForSendingMessage = function (params) {
  return axios.post(chatServiceUrl + '/template/list-for-send', params, {
    headers: buildAuthHeaders()
  });
};

exports.getDetails = function (id) {
  return axios.get(chatServiceUrl + `/conversation/${id}`, {
    headers: buildAuthHeaders()
  });
};

exports.parseTemplateFromDashboardForm = function (params) {
  return axios.post(chatServiceUrl + '/template/parse', params, {
    headers: buildAuthHeaders()
  });
};

exports.submitTemplate = function (params, channelId) {
  return axios.post(chatServiceUrl + '/template/submit?channelId=' + channelId, params, {
    headers: buildAuthHeaders()
  });
};

exports.syncWithFacebook = function (params) {
  return axios.post(chatServiceUrl + '/template/sync', params, {
    headers: buildAuthHeaders()
  });
};
